import React, { useState, useEffect, useRef, useMemo } from "react";
import "./AsciiGlitch.scss";

const AsciiGlitch = ({
  asciiLines = [
    `   ________  ________  ________  ________  ________ `,
    `  /        \\/        \\/        \\/        \\/    /   \\`,
    ` /         /        _/_       _/       __/_       _/`,
    `/         /-        //       //        _/         / `,
    `\\__/__/__/\\________/ \\______/ \\_______/ \\___/____/  `,
  ],
  partialGlitchProbability = 0.24,
  partialGlitchDuration = 320,
  partialGlitchMinDelay = 1000,
  partialGlitchMaxDelay = 3000,
  chaosCharSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+[]{};:'\",.<>/?",
  chaosInterval = 42,
}) => {
  const stableAsciiLines = useMemo(() => asciiLines, []);
  const stableChaosCharSet = useMemo(() => chaosCharSet, []);

  const [chaosState, setChaosState] = useState("auto");
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (chaosState === "locked-off") {
      setChaosState("auto");
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    if (chaosState === "auto") {
      setChaosState("locked-on");
    } else if (chaosState === "locked-on") {
      setChaosState("locked-off");
    } else {
      setChaosState("auto");
    }
  };

  const activeChaos =
    chaosState === "locked-on" || (chaosState !== "locked-off" && isHovered);

  const [chaosLines, setChaosLines] = useState(stableAsciiLines);
  const [partialGlitchLines, setPartialGlitchLines] = useState(null);

  const partialGlitchTimeoutRef = useRef(null);
  const partialGlitchIntervalRef = useRef(null);
  const chaosIntervalRef = useRef(null);

  const generatePartialGlitchLine = (line) =>
    Array.from(line)
      .map((char) =>
        char !== " " && Math.random() < partialGlitchProbability
          ? stableChaosCharSet[
              Math.floor(Math.random() * stableChaosCharSet.length)
            ]
          : char
      )
      .join("");

  const triggerPartialGlitch = () => {
    if (activeChaos) return;
    const newLines = stableAsciiLines.map((line) =>
      generatePartialGlitchLine(line)
    );
    setPartialGlitchLines(newLines);
    partialGlitchTimeoutRef.current = setTimeout(() => {
      setPartialGlitchLines(null);
    }, partialGlitchDuration);
  };

  const schedulePartialGlitch = () => {
    if (activeChaos) return;
    const randomDelay =
      Math.random() * (partialGlitchMaxDelay - partialGlitchMinDelay) +
      partialGlitchMinDelay;
    partialGlitchIntervalRef.current = setTimeout(() => {
      triggerPartialGlitch();
      schedulePartialGlitch();
    }, randomDelay);
  };

  useEffect(() => {
    if (!activeChaos) {
      schedulePartialGlitch();
    } else {
      if (partialGlitchIntervalRef.current)
        clearTimeout(partialGlitchIntervalRef.current);
      if (partialGlitchTimeoutRef.current)
        clearTimeout(partialGlitchTimeoutRef.current);
      setPartialGlitchLines(null);
    }
    return () => {
      if (partialGlitchIntervalRef.current)
        clearTimeout(partialGlitchIntervalRef.current);
      if (partialGlitchTimeoutRef.current)
        clearTimeout(partialGlitchTimeoutRef.current);
    };
  }, [activeChaos]);

  const generateChaosLine = (line) =>
    Array.from(line)
      .map((char) =>
        char === " "
          ? " "
          : stableChaosCharSet[
              Math.floor(Math.random() * stableChaosCharSet.length)
            ]
      )
      .join("");

  useEffect(() => {
    if (activeChaos) {
      chaosIntervalRef.current = setInterval(() => {
        setChaosLines(stableAsciiLines.map((line) => generateChaosLine(line)));
      }, chaosInterval);
    } else {
      if (chaosIntervalRef.current) clearInterval(chaosIntervalRef.current);
      setChaosLines(stableAsciiLines);
    }
    return () => {
      if (chaosIntervalRef.current) clearInterval(chaosIntervalRef.current);
    };
  }, [activeChaos, stableAsciiLines, chaosInterval, stableChaosCharSet]);

  const getDisplayLines = () => {
    if (activeChaos) return chaosLines;
    if (partialGlitchLines !== null) return partialGlitchLines;
    return stableAsciiLines;
  };

  const totalRows = stableAsciiLines.length;
  const getRowColor = (rowIndex) => {
    const opacity = totalRows > 1 ? 1 - (rowIndex / (totalRows - 1)) * 0.8 : 1;
    return `rgba(229,229,229,${opacity.toFixed(2)})`;
  };

  return (
    <div
      className="ascii-glitch"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {getDisplayLines().map((line, rowIndex) => (
        <pre
          key={rowIndex}
          style={{
            margin: 0,
            color: getRowColor(rowIndex),
            transition: "color 0.1s ease",
          }}
        >
          {line}
        </pre>
      ))}
    </div>
  );
};

export default AsciiGlitch;
