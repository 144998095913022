import "./App.scss";
import Banner from "./components/Banner.js";
import WorkingHours from "./components/WorkingHours.js";
import Education from "./components/Education.js";
import AsciiGlitch from "./components/AsciiGlitch.js";

const App = () => (
  <div className="container">
    <main>
      <div className="container-main">
        <Banner />
        <WorkingHours />
        <Education />
      </div>
    </main>
    <div className="container-ascii">
      <AsciiGlitch />
    </div>
  </div>
);

export default App;
