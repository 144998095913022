import React from "react";
import profile from "../profile.png";
import "../App.scss";

const Banner = () => (
  <div>
    <div className="top-section-line">
      <div>
        <span className="symbol">{">"}</span>
        <span className="accent">mstfx</span>
      </div>
      <div>
        {/* Inlined Links: simplified for reduced cognitive load */}
        <a
          target="_blank"
          rel="noreferrer"
          className="mr-8 subdued"
          href="https://www.linkedin.com/in/marc-stauffer/"
        >
          LinkedIn
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          className="subdued"
          href="mailto:mail@marcstauffer.xyz"
        >
          Email
        </a>
      </div>
    </div>
    <div className="top-section-profle pt-24">
      <img src={profile} className="img mr-16" alt="Logo of Marc Stauffer" />
      <div>
        Marc Stauffer
        <div className="subdued">I value practical software</div>
        <div className="subdued">built by teams who care.</div>
      </div>
    </div>
  </div>
);

export default Banner;
